























import Vue from 'vue';

export default Vue.extend({
  name: 'ErrorPage',
  computed: {
    forbidden() {
      return !!this.$route.path.match(/403/)?.length;
    },
  },
});
